<template>
  <div class="videoMian">
    <video
      id="myVideo"
      show-mute-btn
      http-cache
      vslide-gesture
      controls
      :custom-cache="false"
      object-fit="fill"
      :src="imgurl"
      :muted="false"
    ></video>
  </div>
</template>

<script>
export default {
  name: 'Class',
  data() {
    return {
      imgurl: '',
    };
  },
  components: {},
  watch: {},
  created() {},
  mounted() {
    if (localStorage.getItem('teachvideo')) {
      this.imgurl = JSON.parse(localStorage.getItem('teachvideo'));
    } else {
      var search = window.location.href;
      // var search = this.hrefUrl;
      this.search = search;
      var data = this.queryURLParams(this.search);
      this.imgurl = decodeURIComponent(decodeURI(data.imgurl));
    }
  },
  destroyed() {
    if (localStorage.getItem('teachvideo')) {
      localStorage.removeItem('teachvideo');
    }
  },
  methods: {
    queryURLParams(url) {
      let result = {};
      let reg1 = /([^?=&#]+)=([^?=&#]+)/g;
      let reg2 = /#([^?=&#]+)/g;
      url.replace(reg1, (n, x, y) => (result[x] = y));
      url.replace(reg2, (n, x) => (result['HASH'] = x));
      return result;
    },
  },
};
</script>

<style>
.videoMian {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  width: 100vw;
  height: 100vh;
  /* // height: calc(100% - 57px); */
}
video {
  width: 100%;
  object-fit: fill;
  height: 100%;
  display: block;
  /* // 这个是解决video 底部有一丝白边问题。 */
}
</style>
